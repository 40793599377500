@import "../../styles/common/variables.scss";
@import "../../styles/common/mixins.scss";

.tool-bar {
    // position: sticky;
    top: 120px;
    background-color: $white-color;
    z-index: 1;
    width: 100%;
    position: fixed;
    z-index: 99;
    padding: 0px 50px 0px 86px;

    &:after {
        content: "";
        position: absolute;
        top: -50px;
        left: -3px;
        right: -3px;
        bottom: 0;
        background: $white-color;
        z-index: -1;
    }

    .MuiSvgIcon-root {
        font-size: 32px;
    }

    .toolbar-searchbox-wrapper {
        width: 75%;

        .toolbar-searchbox {
            display: flex;
            padding: 6.5px 6px;
            border-radius: 4px;
            border: 1px solid $light-gray-boder-color1;
            margin-right: 30px;
            margin-bottom: 10px;
            @include box-shadow-2;
            .spinner-input-group{
                .input-group-append{
                    position: absolute;
                    right: 54px;
                    padding-top:15px;
                }
            }
            input {
                width: 100%;
                padding: 0 70px 0 14px;
                border: 0;
                background-color: transparent;
                background-image: url(../../assets/img/ic_search.svg);
                background-repeat: no-repeat;
                background-position: calc(100% - 20px) center;
            }
            .filter-btn {
                border-left: 1px solid $light-gray-boder-color1;

                button {
                    all: unset;
                    height: 35px;
                    width: 118px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
                .sort-btn{
                    width: 70px;
                }
            }
        }
        .rbt-searchbox {
            display: flex;
            padding: 0px !important;
            border-radius: 4px;
            border: 1px solid #e2e2e2;
            margin-right: 30px;
            margin-bottom: 10px;
            box-shadow: 0px 0px 3px rgb(0 0 0 / 16%);
            .rbt {
                width: 100%;
                padding: 5px 70px 5px 14px;
                box-shadow: 0px 0px 3px rgb(0 0 0 / 16%);
                .dropdown-menu {
                    inset: 5px auto auto -15px !important;
                }
                .dropdown-item:active{
                    background-color:$secondary-color
                }
                input {
                    padding: 10px 9px 10px 9px;
                }
            }
        }
    }
    .toolbar-tabs-wrapper {
        min-width: 355px;
        margin-right: 160px;
        .btn {
            font-size: 14px;
            height: 50px;
            min-width: 119px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                filter: invert(1);
            }
            .img-holder{
                padding-right: 5px;
            }
            &.active {
                background-color: $primary-color;
                border-color: $primary-color;
                color: $secondary-color;

                img {
                    filter: invert(0);
                }
            }
        }
    }

    .grid-list-icons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 108px;
        height: 50px;
        .gridtype {
            opacity: 50%;
        }
        .active {
            opacity: 100%;
        }
        .cl-icons {
            font-size: 44px;
        }
    }

    @media (max-width: 1099px) {
        .d-flex.align-items-start {
            flex-wrap: wrap;
        }
        .toolbar-searchbox-wrapper {
            order: 2;
            margin-top: 30px;
            .toolbar-searchbox {
                margin-right: 0px;
            }
        }
        .toolbar-tabs-wrapper {
            margin-left: auto;
        }
    }

    @media (max-width: 767px) {
        h4 {
            font-size: 18px;
        }
        .toolbar-searchbox-wrapper {
            order: 3;
        }
        .toolbar-tabs-wrapper {
            width: 100%;
            order: 2;
            margin-right: 0;
            margin-top: 30px;
            .btn-group {
                width: 100%;
                .btn {
                    width: 33.33%;
                    white-space: nowrap;
                }
            }
        }
        .grid-list-icons {
            order: 1;
            margin-left: auto;
        }
    }
}
.no-data{
    position: absolute;
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.assets-items-holder {
    margin-top: 200px;
    padding: 120px 50px 30px 86px;
}
// Filter Drawer
.filter-drawer-wrapper {
    .filter-drawer {
        visibility: visible;
        width: 491px;
        border: 0;

        .offcanvas-header {
            padding: 50px 30px 30px;
            justify-content: flex-start;

            h4 {
                margin: 0;
                margin-left: 10px;
                font-weight: 700;
            }
        }

        .offcanvas-body {
            padding: 0 30px 30px 72px;
        }
        .offcanvas-footer {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 30px;
            box-shadow: 10px 0px 10px #00000029;
        }
    }

    .filter-drawer-fade.show {
        position: fixed;
        inset: 0;
        background-color: rgba($color: #000000, $alpha: 0.3);
        z-index: 1044;
    }

    @media (max-width: 767px) {
        .filter-drawer {
            width: 100%;

            .offcanvas-header {
                padding: 30px 15px;
            }
            .offcanvas-body {
                padding: 0 15px 15px;
            }
        }

        .custom-radio-container {
            margin-bottom: 20px;
        }
        .custom-checkbox-container {
            margin-bottom: 20px;
        }
        .d-flex.align-items-start.mt-20 {
            margin-top: 0 !important;
        }
    }
}

// Add New Asset Form
.drawer-wrapper.add-new-asset {
    .drawer {
        width: 943px;
    }
}
.add-new-asset-form {
    .uploaded-img {
        height: 263px;
        width: 100%;
        position: relative;
        background-color: $light-gray-color;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .delete-btn {
            display: none;
            position: absolute;
            inset: 0;
            z-index: 1;
            background-color: rgba($color: $secondary-color, $alpha: 0.5);
            justify-content: center;
            align-items: center;
            i.cus-icons {
                filter: invert(1) brightness(10);
                width: 35px;
                height: 35px;
            }
        }

        &:hover {
            .delete-btn {
                display: flex;
            }
        }
    }

    .uploaded-img-info {
        display: flex;
        align-items: flex-start;

        .cus-icons {
            min-width: 20px;
        }
        label {
            display: block;
            font-size: 12px;
            color: $light-gray-boder-color2;
        }
    }

    @media (max-width: 991px) {
        .col-12.col-md-6.col-lg-7.pl-50 {
            .mt-20 {
                margin-top: 0 !important;
            }
        }
        .custom-radio-container {
            margin-bottom: 20px;
        }
    }
    @media (max-width: 767px) {
        .pl-50 {
            padding-left: 12px !important;
        }
    }
}
.searchTags {
    max-height: 6.5rem;
    overflow-y: auto;
}

mark[data-markjs] {
    background-color: #f09e07;
  }

// Tab Video Course List
.tab-tool-bar{
    .inner-loader{
        .loader-container{
            background-color:transparent  !important;
            height: 70% !important;
            margin-top: 30vh;
        }
    }
    .highlight-continuer{
        .drawer-wrapper{
            .iv-details{
                mark{
                    background-color:#F5F5F5 !important;
                }
            }
            .Lg-text-heading{
                mark{
                    background-color:#fff !important;
                }
            }
        }
    }
    .check-box-continuer{
        display: flex;
        flex-direction: row;
        .MuiIconButton-colorSecondary:hover{background-color: transparent;}
        .MuiCheckbox-colorSecondary.Mui-checked{color:$primary-color}
    }
    .MuiTypography-body1{font-family: "Work Sans", sans-serif;}
    .input-search{padding: 14px !important;}
    .rbt-searchbox .rbt{
        padding: 5px 14px !important;
        .rbt-menu {
            width: 100% !important;
            .dropdown-divider,.rbt-menu-pagination-option{display: none}
        }
    }
    .cus-icons.playbtn{width: 50px;height: 50px;}
    .select-warning{color:var(--bs-danger)}
    .toolbar-tabs-wrapper{
        min-width: 625px;
        margin-right: 30px;
    }
    .assets-topic-list{
        margin-top: 16%
    }
    .tab-video-list {
        @include box-shadow-2;
        &:hover { box-shadow: 0px 0.5px 6px 0px rgb(0 0 0 / 32%);}
        .listing-card {
             padding: 0 !important;
            .card-body {
                cursor: pointer !important;
                align-items: center !important;
                .time-duration {color: var(--bs-green);}
                .flex-column {
                    .card-title {font-weight: bolder;}
                    .crs-text{
                        max-height: 25px;
                        overflow: hidden;
                        position: relative;
                        .list-info{font-weight:normal !important;}
                        .extension{
                            position: absolute;
                            top: 0;
                            right: 0;
                        }
                    }
                }
            }
        }
    }
}
// Color Variables 
$primary-color: #F3C563;
$secondary-color: #000000;
$secondary-color-80: rgba(0, 0, 0, 0.8);
$secondary-color-90: rgba(0, 0, 0, 0.9);
$white-color:#FFFFFF;
$gray-color: #747A80;
$gray-color2:#939393;
$light-gray-color: #F5F5F5;
$dark-gray-border-color: #707070;
$light-gray-boder-color1: #E2E2E2;
$light-gray-boder-color2: #B9B9B9;
$light-gray-boder-color3: #F8F8F8;
$sidebar-bg: #ECECEC;
$card-img-bg: #ffffff;
$green-color: #01a84e;

// Font weight
$fws: 200, 300, 400, 500, 600, 700, 800, 900;

// Font-sizes
$fss: 10px, 12px, 14px, 16px, 18px, 20px, 24px;
@import '../../styles/common/variables.scss';
.ce-assets-manager{
    background-color: $white-color;
    min-height: 100vh;

    .ce-assets-container{
        // padding: 120px 50px 30px 86px;
        .last-item{
            display: contents;
        }
    }
    .empty{
        padding: 120px 50px 0px 86px;
    }

    @media (max-width: 991px) {
        .ce-assets-container{
            padding-left: 15px; padding-right: 15px;
        }
    }
}


.assets-grid-layout{padding-top: 3px;}
.video-list{
    .card-img{
        &::after{content: ''; position: absolute; inset: 0; background-color: rgba($color: $secondary-color, $alpha: 0.3)}
        .cus-icons{z-index: 1;}
    }
}

.video-edit-drawer{
    .drawer{width: 100%;}

    .video-clip-list-wrapper{margin: -10px;}
    .video-clip-list{
        height: calc(100vh - 210px); overflow: auto; padding: 10px;

        .video-clip-box{
            display: flex; align-items: center; justify-content: space-between; padding: 10px; background-color: $light-gray-color; box-shadow: 0px 0px 6px #00000029; border-radius: 4px; margin-bottom: 20px;

            .clip-details{font-size: 12px;}
            .btn.btn-light{display: flex; align-items: center; justify-content: center; background-color: $white-color; box-shadow: 0px 0px 6px #00000029; width: 32px; min-width: auto; height: 32px; border-radius: 50%; padding: 0;}
        }
    }

    .video-edit-box{
        width: 100%; height: calc(100vh - 220px);
        video{width: 100%; height: 100%; object-fit: cover;}
    }

    @media (max-width: 767px) {
        .offcanvas-body{
            .row{flex-direction: column-reverse;}
        }
        .video-edit-box{height:350px; margin-bottom: 30px;}
        .video-clip-list{height: 350px;}
    }
}


@media (max-width: 991px) {
    .assets-list-layout{
        .liks-btns .btn.mr-30{margin-right: 10px !important; margin-bottom: 10px;}
        
    }
}
@media (max-width: 767px) {
    .assets-list-layout{
        .listing-card.card .card-body .card-img{height: 81px;}
    }
}
// Import Fonts
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');

// Import Variables
@import "variables.scss";
@import "mixins.scss";


// ---------------------- Common Style -----------------------
body {
    font-family: 'Work Sans', sans-serif;
    color: $secondary-color;
    font-size: 14px;
}

body.stop-scrolling {
    overflow: hidden;
}

a,
a:hover {
    outline: none;
    color: inherit;
    text-decoration: none;
}

button,
.btn {
    outline: none;
    cursor: pointer;
    white-space: nowrap;
}

.unset-all {
    all: unset;
}

input {
    &:focus {
        outline: none;
        box-shadow: none !important;
    }

    &::-webkit-input-placeholder {
        color: $gray-color2 !important;
        opacity: 0.5;
    }

    &:-ms-input-placeholder {
        color: $gray-color2 !important;
        opacity: 0.5;
    }

    &::placeholder {
        color: $gray-color2 !important;
        opacity: 0.5;
    }
}

select,
textarea {
    &:focus {
        outline: none;
        box-shadow: none !important;
    }
}

.text-gray-color2 {
    color: $gray-color2;
}

// Custom Icons
.cus-icons {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100%;

    &.playbtn {
        background-image: url(../../assets/img/ic_playbtn.svg);
        width: 34px;
        height: 34px;
    }

    &.tv-playbtn {
       background-image: url(../../assets/img/tv_playbtn.svg);
        width: 34px;
        height: 34px;
    }


    &.audioicon {
        background-image: url(../../assets/img/ic_tab-audio.svg);
        width: 32px;
        height: 40px;
        filter: invert(1);
    }

    &.content-copy-icon {
        background-image: url(../../assets/img/ic_content-copy.svg);
        width: 17px;
        height: 17px;
    }

    &.browser-updated-icon {
        background-image: url(../../assets/img/ic_browser-updated.svg);
        width: 17px;
        height: 17px;
    }

    &.embed-icon {
        background-image: url(../../assets/img/ic_embed.svg);
        width: 17px;
        height: 17px;
    }

    &.copy-link-icon {
        background-image: url(../../assets/img/ic_copy-link.svg);
        width: 17px;
        height: 17px;
    }

    &.my-wishlist-icon {
        background-image: url(../../assets/img/ic_wishlist.svg);
        width: 17px;
        height: 17px;
    }

    &.edit-video-icon {
        background-image: url(../../assets/img/ic_edit-video.svg);
        width: 17px;
        height: 17px;
    }

    &.delete-icon {
        background-image: url(../../assets/img/ic_delete.svg);
        width: 18px;
        height: 18px;
    }

    &.info-icon {
        background-image: url(../../assets/img/ic_info.svg);
        width: 20px;
        height: 20px;
    }

    &.share-icon {
        background-image: url(../../assets/img/ic_share.svg);
        width: 20px;
        height: 22px;
    }

    &.ic-close {
        background-image: url(../../assets/img/ic_close.svg);
        width: 15px;
        height: 15px;
    }

    &.ic-edit {
        background-image: url(../../assets/img/ic_edit.svg);
        width: 20px;
        height: 20px;
    }
}

// Button style
.btn {
    height: 48px;
    box-shadow: none;
    border-radius: 6px;
    padding: 10px 18px;
    font-size: 18px;
    font-weight: 500;
    min-width: 110px;

    &:active,
    &:focus {
        box-shadow: none !important;
    }

    &.btn-primary {
        background-color: $secondary-color;
        color: $white-color;
        border-color: $secondary-color;

        &:hover,
        &:active {
            background-color: $secondary-color-80;
        }
    }

    &.btn-outline {
        border-color: $secondary-color;
        color: $secondary-color;

        &:hover,
        &:active {
            background-color: $secondary-color;
            color: $white-color;
        }
    }

    &.btn-outline-primary {
        border-color: $secondary-color;
        color: $secondary-color;

        &:hover,
        &:active {
            background-color: $secondary-color;
            color: $white-color;
        }
    }

    @media (max-width: 767px) {
        font-size: 14px;
        height: 42px;
    }
}

// Font weight
@each $fw in $fws {
    .fw-#{$fw} {
        font-weight: $fw;
    }
}

// Font-sizes
@each $fs in $fss {
    .fs-#{$fs} {
        font-size: $fs;
    }
}

//margin and padding
@for $i from 1 through 5 {
    $inum: $i * 10;

    .mt-#{$inum} {
        margin-top: $inum + px !important;
    }

    .mr-#{$inum} {
        margin-right: $inum + px !important;
    }

    .mb-#{$inum} {
        margin-bottom: $inum + px !important;
    }
    .delete-asset {
        display: flex;
        align-items: flex-end;
        padding-bottom: 30px;
        justify-content: flex-end;
        .btn{
            background-color: #F3C563;
            border-color: #F3C563;
            color:#000;
            &:hover,
            &:active {
                color:#000;
                background-color: #F3C563;
                border-color: #F3C563;
            }
        }
    }
    .ml-#{$inum} {
        margin-left: $inum + px !important;
    }

    .mx-#{$inum} {
        margin-left: $inum + px !important;
        margin-right: $inum + px !important;
    }

    .my-#{$inum} {
        margin-top: $inum + px !important;
        margin-bottom: $inum + px !important;
    }

    .p-#{$inum} {
        margin: $inum + px !important;
    }

    .pt-#{$inum} {
        padding-top: $inum + px !important;
    }

    .pr-#{$inum} {
        padding-right: $inum + px !important;
    }

    .pb-#{$inum} {
        padding-bottom: $inum + px !important;
    }

    .pl-#{$inum} {
        padding-left: $inum + px !important;
    }

    .px-#{$inum} {
        padding-left: $inum + px !important;
        padding-right: $inum + px !important;
    }

    .py-#{$inum} {
        padding-top: $inum + px !important;
        padding-bottom: $inum + px !important;
    }

}

// Tags
.tags,
.toolbar-tags {
    display: flex;
    align-items: flex-start;
}

.course-list {
    padding-top: 30px;

    span {
        font-weight: 600;
    }
}

.content-text-preview{
    padding-top:20px;
    .content-btn {
        background-color:#000 !important;
        color: #fff !important;
    }
    .content{
        // margin-top: 12px;
        padding: 8px;
        max-height: 50vh;
        overflow-y: auto;
    }
}

.show-more-btn{
    font-size: 14px;
    padding: 0 10px;
    height: 33px;
}

.tag-list {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;

    .tag {
        display: inline-flex;
        font-size: 12px;
        padding: 3px 8px;
        background-color: $white-color;
        color: $gray-color;
        border-radius: 10px;
        box-shadow: 0px 0px 2px #00000029;
        margin: 5px;
        cursor: pointer;

        .tag-close {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -3px;
            margin-right: -8px;
            margin-bottom: -3px;
            margin-left: 6px;
            width: 24px;
            background-color: $light-gray-color;
            box-shadow: 0px 0px 2px #00000029;
            border-radius: 50%;

            img {
                width: 8px;
                height: 8px;
                object-fit: contain;
            }
        }
    }
}

// Listing card
.listing-card.card.wishlist {
    cursor: auto !important;
}

.listing-card.card {
    padding: 5px;
    border-radius: 4px 4px 0px 0px;
    border: 0;
    margin-bottom: 24px;
    @include box-shadow-2;
    cursor: pointer;

    .card-body {
        padding: 0px;

        .card-img {
            position: relative;
            width: 100%;
            height: 121px;
            background-color: $card-img-bg;
            border-radius: 4px 4px 0px 0px;
            margin-bottom: 11px;
            overflow: hidden;

            img {
                // width: 100%;
                height: 100%;
                // object-fit: cover;
                width: 250px;
                object-fit: contain;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }

            .cus-icons {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .card-title {
            font-size: 14px;
            margin-bottom: 11px;
        }

        .list-info {
            font-weight: 600;

            .label {
                font-size: 10px;
                padding-right: 5px;
                color: $gray-color;
                font-weight: normal;
            }
        }

        @media (min-width: 1600px) {
            .card-img {
                height: 140px;
            }
        }

        @media (min-width: 1700px) {
            .card-img {
                height: 150px;
                display: flex;
                justify-content: center;
            }
        }
    }

    &.list-view {
        .card-body {
            display: flex;
            align-items: flex-start;

            .card-img {
                width: 179px;
                margin-bottom: 0;
                margin-right: 20px;
            }
        }
    }
}


// Form Design
.form-label {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
}

.form-control,
.form-select {
    border: 1px solid $light-gray-boder-color2;
    border-radius: 4px;
    font-size: 14px;
    padding: 6px 11px;

    &:focus {
        border-color: $secondary-color;
    }

    &::-webkit-input-placeholder {
        color: $gray-color2 !important;
        opacity: 0.5;
    }

    &:-ms-input-placeholder {
        color: $gray-color2 !important;
        opacity: 0.5;
    }

    &::placeholder {
        color: $gray-color2 !important;
        opacity: 0.5;
    }
}

.custom-from-group {
    position: relative;

    .form-label {
        position: absolute;
        top: -10px;
        left: 10px;
        display: inline-block;
        background-color: $white-color;
        padding: 1px 3px;
        color: $gray-color2;
        font-weight: normal;
        margin: 0;
        z-index: 4;
    }

    .form-control {
        &::-webkit-input-placeholder {
            color: $secondary-color !important;
            opacity: 0.2;
        }

        &:-ms-input-placeholder {
            color: $secondary-color !important;
            opacity: 0.2;
        }

        &::placeholder {
            color: $secondary-color !important;
            opacity: 0.2;
        }
    }

    textarea.form-control {
        resize: none;
        min-height: 80px;
    }

    .file-upload-input {
        display: flex;
        width: 100%;
        border: 1px solid #B9B9B9;
        border-radius: 4px;
        font-size: 14px;

        .input-group {
            flex-wrap: nowrap;
        }

        .file-name {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0px 10px;
            overflow: hidden;
        }

        input[type="file"] {
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            bottom: 0;
            right: 68px;
        }

        .upload-btn {
            min-width: 68px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            min-height: 35px;
            border-radius: 0px 4px 4px 0px;
            font-size: 14px;
            font-weight: normal;
            padding: 0;
        }
    }

    &.tags-input {
        position: relative;

        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 35px;
            min-width: auto;
            padding: 9px;
            font-size: 14px;
            font-weight: normal;
        }
    }
}

.custom-radio-container {
    display: inline-flex;
    position: relative;
    padding-left: 34px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 14px;
    min-height: 26px;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked {
            ~ {
                .checkmark {
                    &:after {
                        display: block;
                    }
                }
            }
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        background-color: $white-color;
        box-shadow: 0px 0px 6px #00000029;
        border: 1px solid $secondary-color;
        border-radius: 50%;

        &:after {
            content: "";
            position: absolute;
            display: none;
            top: 5px;
            left: 5px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: $secondary-color;
        }
    }
}

.custom-checkbox-container {
    display: inline-flex;
    position: relative;
    padding-left: 34px;
    cursor: pointer;
    font-size: 14px;
    min-height: 26px;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked {
            ~ {
                .checkmark {
                    &:after {
                        display: block;
                    }
                }
            }
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        background-color: $white-color;
        border-radius: 4px;
        border: 1px solid $secondary-color;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 8px;
            top: 3px;
            width: 6px;
            height: 13px;
            border: solid $secondary-color;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

// liks-btns
.liks-btns, .content-text-preview {
    .btn {
        background-color: $white-color;
        border-color: $dark-gray-border-color;
        font-weight: 600;
        font-size: 14px;
        padding: 0 10px;
        height: 35px;
        border-radius: 4px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        .cus-icons {
            margin-left: 10px;
        }

        &:hover {
            .cus-icons {
                filter: invert(1);
            }
        }
    }

    .btn:last-child {
        margin-right: 0px !important;
    }

    .link-btn-dropdown {
        position: relative;
        display: inline-block;
        vertical-align: top;

        .link-dropdown-menu {
            position: absolute;
            bottom: 70px;
            right: 0;
            width: 264px;
            min-height: 220px;
            background-color: $white-color;
            box-shadow: 0px 0px 10px #00000029;
            border-radius: 6px;
            z-index: 1;
            padding: 10px;
            display: none;

            &::after {
                content: '';
                position: absolute;
                bottom: -8px;
                right: 25%;
                border-top: 9px solid #fff;
                border-left: 9px solid transparent;
                border-right: 9px solid transparent;
            }

            .search-input {
                .form-control {
                    background-color: $light-gray-color;
                    border-color: $light-gray-color;
                    background-image: url(../../assets/img/ic_search1.svg);
                    background-repeat: no-repeat;
                    background-position: 10px;
                    background-size: 19px;
                    padding-left: 40px;
                    font-size: 12px;
                }
            }

            .tags-input .btn {
                background-color: $primary-color;
                color: $secondary-color;
                box-shadow: 0px 0px 6px #00000029;
                border-color: $primary-color;
                font-weight: 500;
                margin-bottom: 0px;
            }

            .wishlist {
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    max-height: 150px;
                    overflow-y: auto;

                    li {
                        display: flex;
                        position: relative;
                        min-height: 35px;
                        width: 100%;
                        padding: 10px 30px 10px 10px;
                        border: 1px solid $light-gray-boder-color2;
                        border-radius: 4px;
                        margin-bottom: 10px;
                        cursor: pointer;

                        .cus-icons.my-wishlist-icon {
                            min-width: 14px;
                            width: 14px;
                            height: 14px;
                            margin-top: 3px;
                        }

                        span {
                            margin-left: 10px;
                        }

                        &.active::after {
                            content: '';
                            position: absolute;
                            right: 11px;
                            top: 10px;
                            width: 5px;
                            height: 11px;
                            border: solid #13984B;
                            border-width: 0 2px 2px 0;
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }

    }
}

// Side Drawer
.drawer-wrapper {
    .drawer {
        visibility: visible;
        width: 900px;
        border: 0;

        .offcanvas-header {
            padding: 30px 30px 20px;
            justify-content: flex-start;

            h4 {
                margin: 0;
                margin-left: 10px;
                font-weight: 700;
            }
        }

        .offcanvas-body {
            padding: 0 30px;
        }
        .offcanvas-footer {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 30px;
            box-shadow: 10px 0px 10px #00000029;
        }
    }

    .drawer-fade.show {
        position: fixed;
        inset: 0;
        background-color: rgba($color: $secondary-color, $alpha: 0.3);
        z-index: 1044;
    }

    @media (max-width: 991px) {
        .drawer {
            .offcanvas-header {
                padding: 30px 15px 20px;
            }

            .offcanvas-body {
                padding: 0 15px;
            }
        }
    }
}

// Image and Video Detial Box
.iv-detail-box {
    .iv-image {
        width: 100%;
        height: 552px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
            width: auto!important;
            height: auto;
            object-fit: cover;
        }
    }

    .iv-details {
        background-color: $light-gray-color;
        padding: 30px;
        .not-migrated-msg{
            color:var(--bs-danger);
            font-weight: 500;
        }
        .asset-header{
            display: flex;
            font-size: 16px;
            text-align: center;
            justify-content: space-between;
            font-weight: 500;
        }
    }

    &.video-details {
        .iv-image {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                inset: 0;
                background-color: rgba($color: $secondary-color, $alpha: 0.5);
            }

            .cus-icons.playbtn {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                width: 60px;
                height: 60px;
            }

            .btn.btn-light {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                bottom: 30px;
                left: 50%;
                transform: translate(-50%, 0);
                z-index: 1;
                height: 35px;
                min-width: auto;
                padding: 0 10px;
            }
        }
    }

    .audio-details {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: $card-img-bg;
        padding: 10px;

        .cus-icons.audioicon {
            position: absolute;
            width: 60px;
            height: 60px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    @media (max-width:991px) {
        .iv-image {
            height: 450px;
        }

        .liks-btns .btn {
            margin-right: 10px !important;
            margin-bottom: 10px;
        }
    }

    @media (max-width:767px) {

        .iv-image {
            height: 350px;
        }

        .iv-image.videos {
            height: auto !important;
        }
    }
}

.error {
    color: red;
}

.asset-fit {
    width: 100%;
    height: 100%;
}

.link-dropdown-menu.show {
    display: block !important;
}

.modal.fade.show {
    display: block;
    background: #0000003b;
}


// Custom Scroll Style
::-webkit-scrollbar {
    width: 8px;
    height: 7px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba($color: $secondary-color, $alpha: 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba($color: $secondary-color, $alpha: 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: $green-color;
}

::-webkit-scrollbar-thumb:window-inactive {
    background: $green-color;
}

.border-bottom {
    border: none;
    border-bottom: 2px solid #000 !important;
}

.mr-2 {
    margin-right: 2rem !important;
}

.pointer {
    cursor: pointer;
}

.no-pointer {
    cursor: auto;
}

.clear__tag {
    margin-top: 5px;
    margin-right: 5px;
}

.mb-20 {
    margin-right: 30px !important;
}

.ml-2 {
    margin-left: 2rem;
}

.fs-1_1{
    font-size: 1.1rem;
}
.highlight {
    background-color: $green-color !important;
    color: #fff !important;
}

.tag_filter {
    display: block !important;
}

.tag_filter_search {
    margin-bottom: 1rem;
    width: 92%;
}

.h-45r {
    height: 4.5rem;
}

.filtered__tags {
    position: relative;
    bottom: -3px;
}

.offcanvas-header.justify-left {
    justify-content: flex-end !important;
}
.edit-with-icon{
    border: 2px solid;
    border-radius: 10px;
    padding: 0.1rem 0.3rem;
}
.drawer-wrapper .edit-in-drawer{
    width: 18px;
    margin-bottom: -4px;
}
.tag-list{
    margin-top: -8px;
}
.tag_filter_search{
    margin-top: -5px;
}
.tag-holder{
    margin: 5px;
    padding-left: 10px;
    .tag{
        font-size: 16px !important;
        color: #000 !important;
    }
}
.gen-img-instr {
    font-size: 12px;
    padding-right: 5px;
    color: $gray-color;
    font-weight: normal;
}
.multilingual-box{
    min-height: 100px;;
}

.language-editor{
    position: relative;
    .language-save{
        position: absolute;
        bottom: 10px;
        right: 10px;
        button.btn{
            font-size: 16px;
            padding: 2px;
            height: 30px;
            background-color: #13984B;
            color: white;

        }
    }
}
.w-35{
    width: 35% !important;
}

/* Custom styles for toast */
.custom-toast {
   
    font-weight: bold; /* Make font bold */
    padding: 10px;
  }

.loader{
    background: url(../../assets/img/loader3.gif);
    background-size: contain;
    width: 100%;
    height: 100%;
    max-width: 50px;
    max-height: 50px;
    background-position: center;
    background-repeat: no-repeat;
}
.loader-container{
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #fff, $alpha: 0.8);
    width: 100%;
    height: 100%;
    z-index: 999;
    padding: 8px;
}
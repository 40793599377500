@import '../styles/common/variables.scss';
@import '../styles/common/common.scss';

.sign-in{
    display: flex; height: 100vh; align-items: center; justify-content: center;
    .sign-in-wrapper{border: 1px solid $light-gray-boder-color1; width: 800px; box-shadow: 0 3px 10px 0 rgba($secondary-color,0.32);}

    .intro-section{
        display: flex; flex-direction: column; height: 100%; justify-content: center; padding: 20px;

        h5{margin-top: 15px;}
        .divider{background-color: $green-color; border-radius: 34px; height: 5px; margin: 10px 0; width: 130px;}
        .copyright-heading{color: $secondary-color; font-size: 12px; font-weight: 600;}
    }

    .signup-form{
        display: flex; flex-direction: column; justify-content: center; min-height: 350px; padding: 30px;
        .form-group{
            label{font-size: 13px; font-weight: 500;}
            .error-msg{color: red; font-size: 12px;}
        }
        .btn-sign{background-color: #01a84e; color: #fff; height: 35px; padding: 0px; display: flex; justify-content: center; align-items: center;}
    }

    @media (max-width:767px) {
        .sign-in-wrapper{
            width: calc(100% - 30px);

            .ps-0{padding:0px !important;}
        }
        .signup-form{padding: 0px 8px 30px; min-height: auto;}
    }
}
@import '../../styles/common/variables.scss';
@import '../../styles/common/mixins.scss';
@import '../../styles/common/functions.scss';

.ce-assets-mn-sidebar {
    position: fixed; left: 0; top: 70px; transition: 0.3s width ease-out; width: 275px; background-color: $sidebar-bg; height: calc(100% - 70px); z-index: 100; overflow: hidden; @include box-shadow-1; display: flex; flex-direction: column;

    ul {
        @include reset-list; margin-top: 10px; width: 275px;

        li {
            display: flex; align-items: center; padding: 10px 30px 10px 20px; border-bottom: 1px solid #ddd; cursor: pointer;

            .item-icon {width: 24px;height: 24px;object-fit: scale-down;}
            span {padding-left: 22px; font-weight: 500;}

            &.logout{border-top: 1px solid #ddd;margin-bottom: 10px;}
        }
    }
}

.side-nav-close {width: 0;overflow: hidden;}

@media (max-width:767px) {
    .ce-assets-mn-sidebar {
        width: 100%;

        ul{width: 100%;}
    }
    .side-nav-close {width: 0;}
}
@import '../../styles/common/mixins.scss';
@import '../../styles/common/variables.scss';

.ce-assets-manager-header{
    position: fixed; left: 0; right: 0; top: 0; height:70px; background-color: $white-color; z-index: 101; padding: 0 50px 0 30px; cursor: pointer; @include box-shadow-1; 
    
    nav{height: 100%}
    
    ul{
        @include reset-list; @include flex-items; margin-bottom: 0; height:100%;
        
        .ce-asset-hero-head{padding-left: 30px; margin-bottom: 0; font-weight: 700;}
    }
    .nav-flex-items{@include flex-items; height: 100%;}
    .brand-logo{width:180px;}

    @media (max-width: 767px) {
        padding: 0 15px;

        ul .ce-asset-hero-head{padding-left:10px}
    }

    @media (max-width: 575px) {
        ul .ce-asset-hero-head{font-size: 18px;}
        img{width: 155px !important;}
    }
}
.ml-n-10{
    margin-left: -10px !important;
}


@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@mixin box-shadow-1 {
  box-shadow: 0px 3px 6px #00000029;
}

@mixin box-shadow-2 {
  box-shadow: 0px 0px 3px #00000029;
}

@mixin box-shadow-3 {
  box-shadow: 0 3px 6px 0 rgba( 0, 0, 0, 0.32);
}



@mixin flex-items{
  display: flex;
  justify-content:space-between;
  align-items: center;
}
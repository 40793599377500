.video-element {
  width: 100%;
  height: 100%;
}

.iv-image {
  width: 828px;
  height: 552px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* ------------------ Do not add this css ------------------ */

.custom-payer-ic {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
}

.ic-skip-previous {
  height: 40px;
  width: 40px;
  background-image: url(/assets/img/ic_skip-previous.svg);
}

.ic-fast-backward {
  height: 40px;
  width: 40px;
  background-image: url(/assets/img/ic_fast-backward.svg);
}

.ic-play {
  height: 40px;
  width: 40px;
  background-image: url(/assets/img/ic_play.svg);
}

.ic-pause {
  height: 40px;
  width: 40px;
  background-image: url(/assets/img/ic_pause.svg);
}

.ic-fast-forward {
  height: 40px;
  width: 40px;
  background-image: url(/assets/img/ic_fast-forward.svg);
}

.ic-skip-next {
  height: 40px;
  width: 40px;
  background-image: url(/assets/img/ic_skip-next.svg);
}

.ic-forward {
  height: 40px;
  width: 40px;
  background-image: url(/assets/img/ic_forward.svg);
}

.ic-backward {
  height: 40px;
  width: 40px;
  background-image: url(/assets/img/ic_backward.svg);
}

.vjs-big-play-button {
  display: none !important;
}

.video-js .vjs-play-progress {
  background-color: red !important;
}

.custom-player-button {
  font-size: 3em;
  line-height: 1.5em;
  height: 1.63332em !important;
  width: 3em !important;
  position: absolute !important;
  top: 50%;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  transition: all 0.4s;
  display: none !important;
}

.button-forward {
  right: 20%;
}

.button-backward {
  left: 20%;
}

.custom-player-button.seek-show {
  display: block !important;
}

.cus-icons.detail {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.cus-icons.detail.hide {
  display: none;
}

.vjs-button > .vjs-icon-subtitles:before{

  font-size: 1.8em;
  line-height: 1.67;
}
.video-js .vjs-overlay-bottom{
  bottom: 0 !important;
  width: 100% !important;
  height: 50%;
  left: 0 !important;
  margin-left: 0 !important;
  border:1px solid rgba(0, 0, 0, 0.189);

}
.playerOverlay{
  color: #000 !important;
}
.overlay-close{
  height: 40px;
  display: flex;
  justify-content: flex-end;
}
.video-js .vjs-overlay-background{
  background-color: #fff !important;
}
.ic-close{
  cursor: pointer;
}